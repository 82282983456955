<template>
  <component
    :is="manualProduct ? 'div' : GenericSideMenu"
    position="right"
    class="QuickBuy"
    header-class="bg-transparent border-b-0 absolute top-0 p-16 z-40"
    content-class="!p-0"
    small
    @close="uiStore.closeAllModal()"
  >
    <div
      v-if="loading"
      class="p-32 flex items-center justify-center"
    >
      <LoadingIcon />
    </div>
    <div v-else-if="quickBuyProduct">
      <div class="relative">
        <div v-if="quickBuyActiveVariant.hasBuyMoreCampaign" class="absolute w-70 h-70 top-0 left-0 mt-16 ml-16 z-40">
          <img
            :src="`/splashes/buy-more-${globalContent.getLocale}.svg`"
            alt=""
          >
        </div>
        <div
          v-else-if="quickBuyProduct.isNewProduct || quickBuyActiveVariant.isOnSaleForUser"
          class="absolute w-70 h-70 top-0 left-0 mt-16 ml-16 z-40"
        >
          <img
            :src="quickBuyProduct.isNewProduct
              ? '/splashes/bywe_donut-news.svg'
              : '/splashes/bywe_donut-sale.svg'"
            alt=""
          >
        </div>

        <div v-if="additionalImage" class="grid grid-cols-2">
          <nuxt-img
            v-if="images.length"
            :src="images[0].file"
            provider="norce"
            alt=""
            preset="standard"
            loading="lazy"
            sizes="mob375:50vw mob390:50vw mob414:50vw"
          />
          <img
            v-else
            src="/icons/no-image.svg"
            class="w-full"
            alt=""
          >
          <div :style="additionalImageStyle">
            <video
              v-if="checkIfVideoFile(additionalImage.file)"
              class="w-full h-full object-cover mb-4"
              loop
              muted
              autoplay
              playsinline
            >
              <source :src="additionalImage.file" type="video/mp4">
            </video>
          </div>
        </div>
        <div v-else>
          <SimpleSwiper
            v-if="images.length > 0"
            :images="images"
            :slide-margin="2"
            :slide-width="100"
            :lazy-first-image="false"
            sizes="desk:350px"
            ratio="3:3"
          />
          <img
            v-else
            src="/icons/no-image.svg"
            class="aspect-product w-full max-h-[480px]"
            alt="no image"
          >
        </div>
      </div>

      <div class="p-16 pb-0">
        <NameAndCategory
          :product-item="quickBuyProduct"
          :active-variant="quickBuyActiveVariant"
          :show-part-no="false"
          :show-description="false"
        />

        <ProductPrice
          :key="quickBuyActiveVariant.partNo"
          :crossell="isCrossSell"
          :product-item="quickBuyProduct"
          :active-variant="quickBuyActiveVariant"
          design="standard"
        />

        <VariantSelector
          :product-item="quickBuyProduct"
          :active-variant="quickBuyActiveVariant"
          design="quickbuy"
          @update-active-variant="(variant) => quickBuyActiveVariant = variant"
        />
        
        <BuyBox
          v-if="quickBuyActiveVariant"
          :key="quickBuyActiveVariant.partNo"
          :active-variant="quickBuyActiveVariant"
          :product-item="quickBuyProduct"
          design="quickbuy"
          :crossell="isCrossSell"
          class="mb-24 mt-12"
          @add-to-cart="addToCart"
          @adding-to-cart="() => adding = true"
        />
      </div>
    </div>
  </component>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useUiStore } from '~/store/ui';
import { useCartStore } from '~/store/cart';
import Product, { ProductModel } from '~/models/product';
import { ProductVariantModel } from '~/models/productVariant';
import { NorceMediaSetCodes } from '~/constants/norceCodes';
import GenericSideMenu from '~/components/menu/GenericSideMenu.vue';
import BuyBox from '~/components/product-page/BuyBox.vue';
import VariantSelector from '~/components/product-page/VariantSelector.vue';
import ProductPrice from '~/components/product-page/ProductPrice.vue';
import NameAndCategory from '~/components/product-page/NameAndCategory.vue';
import SimpleSwiper from '~/components/globals/SimpleSwiper.vue';

import { DatasourceObjectPosition } from '~/constants/types/storyblok';
import { checkIfVideoFile, convertObjectPositionToBgPosition, storyblokScaleImage } from '~/util/imageUtil';
import LoadingIcon from '~/components/generic/LoadingIcon.vue';
import useAlgoliaFetch from '~/composeables/useAlgoliaFetch';
import { useGlobalContentStore } from '~/store/globalContent';
const globalContent = useGlobalContentStore();

const cartStore = useCartStore();
const uiStore = useUiStore();
const loading = ref(true);
const { getProductByPartNo } = useAlgoliaFetch();

/**
 * Sending prop manualProduct uses this product, otherwise it uses cartstore
 */
const props = defineProps<{
  manualProduct?: ProductModel,
  additionalImage?: {
    file: string;
    imagePosition: DatasourceObjectPosition;
  } | null, // can also be a video
}>();

const quickBuyProduct = ref<ProductModel | null>(props.manualProduct ? props.manualProduct : cartStore.currentlyBuying);
if (!quickBuyProduct.value) {
  throw createError({
    statusCode: 500,
    statusMessage: 'Quickbuy without a product',
  });
}
const setPrimaryToActive = () => {
  if (quickBuyProduct.value) {
    let primaryVariant = quickBuyProduct.value.variants.findIndex((f) => f.isPrimary);
    if (primaryVariant === -1) {
      primaryVariant = 0;
    }
    quickBuyActiveVariant.value = quickBuyProduct.value.variants[primaryVariant];
  }
};
const quickBuyActiveVariant = ref<ProductVariantModel>(quickBuyProduct.value.variants[0]);
setPrimaryToActive();

// check if entire product is loaded or only one variant
if (!quickBuyProduct.value.limitedVariantLoaded) {
  loading.value = false;
} else {
  const res = await getProductByPartNo(quickBuyProduct.value.partNo);
  if (res) {
    quickBuyProduct.value = Product.create(res.hits);
    setPrimaryToActive();
    loading.value = false;
  } else {
    uiStore.setTemporaryError('Failed to load product', 0);
  }
}

const isCrossSell = cartStore.isBuyingUpsellProduct;
const selectedPartNo = ref(quickBuyProduct.value.partNo);
if (quickBuyProduct.value.variants.length) {
  selectedPartNo.value = quickBuyProduct.value.variants[0].partNo;
}

const quickBuyCurrentImageList = ref<NorceMediaSetCodes>(NorceMediaSetCodes.DefaultImages);

const adding = ref(false);

const addToCart = async() => {
  if (uiStore.quickBuyCloseAfterAdd) {
    uiStore.closeAllModal();
  }
};
const images = computed(()=>{
  return quickBuyProduct.value?.imagesAndVideo(quickBuyCurrentImageList.value, quickBuyActiveVariant.value) || [];
});

const additionalImageStyle = computed(()=> {
  if (props.additionalImage?.file
      && !checkIfVideoFile(props.additionalImage.file)) {

    const url = storyblokScaleImage(props.additionalImage.file, 758);

    return {
      backgroundImage: `url(${url})`,
      backgroundSize: 'cover',
      backgroundPosition: convertObjectPositionToBgPosition(props.additionalImage.imagePosition),
    };
  }
  return {};
});

defineEmits(['closeMenu']);
</script>

<style scoped lang="postcss">
</style>
